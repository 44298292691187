// Documentation link: https://kit.svelte.dev/docs/routing#layout-layout-js
export const prerender = true;

import "$lib/LocaleSelect.svelte";
import { browser } from "$app/environment";
import { locale, waitLocale } from "svelte-i18n";

export const load = async () => {
  if (browser) {
    locale.set(window.navigator.language);
  }
  await waitLocale();
};
