<script>
  import LocaleSelect from "$lib/LocaleSelect.svelte";
  import Account from "$lib/Account.svelte";
  import { _ } from "svelte-i18n";
  import { getTitles } from "$lib/search.js";
  import { settings_store } from "$lib/store.js";
  import { get } from "svelte/store";

  let isOpen = false;
  let old_setting_values = "";

  function openAccount() {
    isOpen = true;
    old_setting_values = JSON.stringify(get(settings_store));
    document.body.style.overflow = "hidden";
  }

  function closeAccount() {
    isOpen = false;
    document.body.style.overflow = "";
    const new_values = JSON.stringify($settings_store);
    if (old_setting_values !== new_values) {
      getTitles();
    }
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="header">
  <img src="/logos/trovio_logo_beta.svg" alt="Trovio logo" class="logo" width="23" height="8" />
  <div class="settings">
    <button
      class="filter-button"
      title={$_("filter-settings")}
      alt="menu item: filter settings"
      on:click={() => openAccount()}
      on:keypress={() => openAccount()} />
    <LocaleSelect />
  </div>
</div>
<Account {isOpen} on:close={closeAccount} />

<!-- ---------------------------------------------------------------------- -->

<style>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    /* background-color: var(--background); */
    color: var(--white);
    width: 90%;
    transition: all 0.3s;
  }
  .logo {
    display: inline-block;
    margin-top: 4px;
    width: auto;
    height: 36px;
    object-fit: cover;
  }
  .settings {
    display: flex;
    gap: 12px;
    height: 48px;
    align-items: center;
  }
  .filter-button {
    background: url("/buttons/btn_account.svg") no-repeat;
    cursor: pointer;
    border: none;
    height: 48px;
    aspect-ratio: 1 / 1;
    object-fit: cover; /* Cover the container while preserving aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  .filter-button:hover {
    background: url("/buttons/btn_account_hover.svg") no-repeat;
  }
  @media (max-width: 480px) {
    .header {
      width: 98%;
    }
  }
</style>
