<script>
  import Select from "svelte-select";
  import { locale } from "svelte-i18n";
  import { register, init } from "svelte-i18n";
  import { defaultLanguage } from "$lib/LocaleConstants.js";
  import { settings_store, startup_state, call_get_titles, call_get_random_prompt } from "$lib/store.js";
  import { onMount } from "svelte";

  let uniqueId;

  init({
    fallbackLocale: defaultLanguage,
    initialLocale: $locale
  });

  register("en", () => import("$lib/lang/en.json"));
  register("de", () => import("$lib/lang/de.json"));
  register("fr", () => import("$lib/lang/fr.json"));
  // register("it", () => import("$lib/lang/it.json"));
  //FIXME: enable when API is ready: https://github.com/doklab/mowizz-search/issues/180

  let items = [
    { value: "en", label: "EN" },
    { value: "de", label: "DE" },
    { value: "fr", label: "FR" }
    // { value: "it", label: "Italiano" },
    //FIXME: enable when API is ready: https://github.com/doklab/mowizz-search/issues/180
  ];

  function sanitizeLocale(chosenLocale) {
    const result = items.find((item) => item.value === chosenLocale);
    return result ? result.value : defaultLanguage;
  }

  function handleSelectionChange() {
    if ($startup_state && uniqueId) {
      call_get_random_prompt.set(true);
    } else {
      call_get_titles.set(true);
    }
  }

  let justValue = sanitizeLocale($locale);
  $: {
    $locale = justValue;
    $settings_store.selected_locale = justValue;
  }

  onMount(() => {
    // Generate a unique ID for this instance of the component
    uniqueId = `language-select-${Math.random().toString(36).substr(2, 9)}`;
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<!-- <label for={uniqueId}>Select preferred language</label> -->
<div class="selector">
  <Select
    {items}
    value={$locale}
    clearable={false}
    id={uniqueId}
    bind:justValue
    focused={false}
    searchable={false}
    showChevron={false}
    on:change={() => handleSelectionChange()}
    inputStyles="user-select: none; cursor: pointer; appearance: none;"
    --height="48px"
    --border="solid 1px transparent"
    --border-radius="8px"
    --border-hover="solid 1px var(--white)"
    --border-focused="solid 1px var(--lightest-blue)"
    --multi-item-active-outline="solid 1px var(--lightest-blue)"
    --list-border-radius="8px"
    --value-container-padding="0"
    --item-color="var(--icon-light-blue)"
    --selected-item-color="var(--light-grey)"
    --item-hover-bg="var(--white-30)"
    --item-hover-color="var(--white)"
    --list-background="var(--dark-grey)"
    --item-is-active-color="var(--white)"
    --item-is-active-bg="var(--solid-blue)"
    --background="var(--dark-grey)"
    ---hover-background="var(--black)"
    --width="62px" />
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .selector {
    padding-right: 0.5em;
    position: relative;
    user-select: none;
    cursor: pointer;
    appearance: none;
  }
  .selector :global(div) {
    /* Fix for the font of the visible selected locale when the dropdown is closed. 
       The itme-color does not work for some reason. 
       Maybe we could fix this nicer by a custom 'slot' definition on the svelte-select.
       https://github.com/rob-balfre/svelte-select?tab=readme-ov-file 
    */
    color: var(--icon-light-blue) !important;
    font-weight: 400;
    user-select: none;
    cursor: pointer;
    appearance: none;
  }
  /* label {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  } */
  :global(select),
  :global(input) {
    appearance: none; /* Removes native styles */
    -webkit-appearance: none;
    -moz-appearance: none;
    user-select: none; /* Prevents text cursor */
    cursor: pointer; /* Makes it look like a button */
    outline: none; /* Remove focus outline */
  }

  /* Target elements with ARIA roles */
  :global([role="listbox"]),
  :global([role="option"]) {
    user-select: none; /* Prevents text selection */
    cursor: pointer;
  }

  /* Optional: additional styling */
  :global(select:focus),
  :global(input:focus) {
    outline: none; /* Remove outline on focus */
  }
</style>
